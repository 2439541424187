<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-2">Experiment 3 - Pre-Lab Exercise: Part II</h2>

      <p class="mb-4">
        In a hypothetical experiment, a student uses
        <number-value :value="massMg" unit="\text{g}" />
        combined with
        <latex-number :number="volHCl" unit="\text{mL}" />
        of 1.00 M HCl to generate hydrogen gas. Answer each of the questions below regarding this
        experiment. Assume room temperature and 1 atm conditions.
      </p>

      <p class="mb-2">a) How many mol of Mg metal were used in the reaction?</p>

      <calculation-input
        v-model="inputs.molMg"
        prepend-text="$\text{Mg:}$"
        append-text="$\text{mol}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) How many mol of HCl were used in the reaction?</p>

      <calculation-input
        v-model="inputs.molH2"
        prepend-text="$\text{HCl:}$"
        append-text="$\text{mol}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">c) Which of the two would be the limiting reagent?</p>

      <v-radio-group v-model="inputs.LR" class="ml-6 mb-n7" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'OleMiss116Lab3PrelabQ2',
  components: {
    LatexNumber,
    NumberValue,
    ChemicalNotationInput,
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        molMg: null,
        molHCl: null,
        LR: null,
      },
      options: [
        {text: '$\\text{Mg}$', value: 'Mg'},
        {text: '$\\text{HCl}$', value: 'HCl'},
        {text: '$\\text{Neither}$', value: 'neither'},
      ],
    };
  },
  computed: {
    massMg() {
      return this.taskState.getValueBySymbol('massMg').content;
    },
    molRatio() {
      return this.taskState.getValueBySymbol('molRatio').content;
    },
    volHCl() {
      return ((this.massMg.toFloat() / 24.305) * this.molRatio.toFloat() * 1000).toFixed(2);
    },
  },
};
</script>
<style scoped></style>
